import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import client from '../contentfulClientToken';

export default function Home() {

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data on component mount
    /*    useEffect(() => {
           const fetchPictures = async () => {
               try {
                   const entryId = "62j4KYOawgsi8hvbXsAMmU";  // Replace with your actual entry ID
                   const response = await client.getEntry(entryId);
                   console.log(response);
   
                   if (response.fields && response.fields.pictures) {
                       const fetchedImages = response.fields.pictures.map(picture => ({
                           url: picture.fields.file.url,
                           title: picture.fields.title,
                       }));
                       setImages(fetchedImages);
                   } else {
                       console.warn("No pictures found in response.");
                   }
               } catch (error) {
                   console.error("Error fetching images:", error);
               } finally {
                   setLoading(false);
               }
           };
   
           fetchPictures();
       }, []);
   
       if (loading) {
           return <p className="loading">loading</p>
               ;
       }
    */

    return (
        <div>
            <ul className='menu'>
                <li><Link to="/analog">analog</Link></li>
                <li><Link to="/digital">digital</Link></li>
            </ul>

            <div className="studio-picture">
                <img src="../content/Oncle-Jean-Baptiste_labo-photo_1905.jpg" alt="Photography lab 1905" />
            </div>

            <div className="description">
                <p>
                    I once thought that the quest to embellish reality through retouching was a symptom of our digital age. I had to reassess this belief after seeing this black-and-white photo my father once sent me.
                    Taken in 1905 in the photolab of his uncle in Algiers, the image shows men sitting in a chain, stylizing portraits using analog tools. The line between the real and the altered, between embellishing and distorting reality, proves impossible to define, the analog beeing as impure as the digital.
                    _
                    The pictures displayed in the <Link style={{ color: 'black' }} to="/analog">analog</Link> section were taken on film with a Mamiya RZ67 between 2015 and 2021. This medium, paired with the camera, made photography as tangible as it gets. Yet, as most of the images we consume today are viewed through screens, this material is inevitably subjected to digitization.
                    Once developed, scanned, and stored on a hardware, these images lose their materiality, reduced to mere sequences of 0s and 1s. The <Link style={{ color: 'black' }} to="/digital">digital</Link> section embraces this transformation, allowing AI to reinterpret the data, giving it a new life by remodelling and distorting it.
                    _

                    ff87c3.eu/
                </p>

                {/*  <p>
                    I thought the quest for embelishment of reality through retouching was a symptom of our digital age but I had to reassess that after seeing this black and white photo my father once sent me.
                    Taken in the photolab of his oncle, in Alger, 1905, the men sitting in a chain are nothing less than stylising the portraits using analog tools.
                    The line between real and not, between embelishing and distorting the real, is impossible to draw.
                    ___

                    The pictures shown in the analog section were taken on film with a Mamiya RZ67 between 2015 and 2021.
                    The medium, paired with this camera, made photography as tangible as it gets.
                    But as most of the imagery we consume today is through a screen, analog material is forced to undergo digitization.
                    Once developed, scanned and stored on a hardrive, images lose their materiality, becoming a mere sequence of 0s and 1s.
                    The digital section is letting AI remodel this data, distort it, give it a new life.

                    ___

                    https://ff87c3.eu/
                </p> */}

                {/*                 Why so much 'technostalgia' in the era of digital photography?
 */}            </div>
        </div>
    );
}