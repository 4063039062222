import React, { useState, useEffect, useRef } from "react";

import client from '../contentfulClientToken';

import { Link } from 'react-router-dom';

export default function Analog() {
    const [imagesAnalog, setImagesAnalog] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [loadedCount, setLoadedCount] = useState(0);
    const [randomPosition, setRandomPosition] = useState({ top: 0, left: 0 });
    const intervalRef = useRef(null);

    const [isFaded, setIsFaded] = useState(false);

    /* 
        const handleMouseDownGradient = () => {
            setIsFaded(true);
        };
    
        const handleMouseUpGradient = () => {
            setIsFaded(false);
        };
     */

    /*     const fetchPictures = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "ronaldTest",
                });
    
                if (response.items.length > 0 && response.includes?.Asset) {
                    const fetchedImages = response.includes.Asset.map((asset) => ({
                        url: asset.fields.file.url,
                        title: asset.fields.title,
                    }));
                    setImages(fetchedImages);
                } else {
                    console.warn("No images found in response.");
                }
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };
    
     */

    const fetchPictures = async () => {
        try {
            const entryId = "6yWtzZS8veCBNuidxVMHeG";  // Replace with your actual entry ID
            const response = await client.getEntry(entryId);
            console.log(response);

            if (response.fields && response.fields.pictures) {
                const fetchedImages = response.fields.pictures.map(picture => ({
                    url: picture.fields.file.url,
                    title: picture.fields.title,
                }));
                setImagesAnalog(fetchedImages);

            } else {
                console.warn("No images found in response.");
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const startInterval = () => {
        setIsFaded(false);
        if (imagesAnalog.length > 0) {
            intervalRef.current = setInterval(() => {
                setCurrentImageIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % imagesAnalog.length;
                    generateRandomPosition(); // Update position on index change
                    return nextIndex;
                });
            }, 50); // Update image every 50ms
        }
    };

    const stopInterval = () => {
        setIsFaded(true);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const generateRandomPosition = () => {
        const randomTop = Math.random() * 80; // Limit within 80% of viewport height
        const randomLeft = Math.random() * 80; // Limit within 80% of viewport width
        setRandomPosition({ top: `${randomTop}vh`, left: `${randomLeft}vw` });
    };

    const handleImageLoad = () => {
        setLoadedCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === imagesAnalog.length) {
                setIsAllLoaded(true);
            }
            return newCount;
        });
    };

    useEffect(() => {
        fetchPictures();

        /*  const handleResize = () => setWindowWidth(window.innerWidth);
         window.addEventListener("resize", handleResize);
 
         return () => {
             window.removeEventListener("resize", handleResize);
             stopInterval();
         }; */
    }, []);

    useEffect(() => {
        if (isAllLoaded) {
            startInterval();
        }
        return () => stopInterval();
    }, [isAllLoaded]);

    const getImageUrl = (image) => {
        let width = 650; // default width
        if (windowWidth < 768) {
            width = 500; // width for mobile devices
        } else if (windowWidth < 1200) {
            width = 600; // width for tablets
        }
        return `https:${image.url}?w=${width}`;
    };

    const handleMouseDown = () => stopInterval();
    const handleMouseUp = () => startInterval();

    return (

        <>
            {/*     <div
                className={`gradient ${isFaded ? "faded" : ""}`}
           
            ></div> */}

            <div className="pictures-flashing-container">

                {imagesAnalog.length > 0 ? (
                    imagesAnalog.map((image, index) => (
                        <div key={index}>
                            <img
                                src={getImageUrl(image)}
                                alt={image.title}
                                style={{
                                    display: index === currentImageIndex ? "block" : "none",
                                }}
                                onLoad={handleImageLoad}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchEnd={handleMouseUp}
                            />
                            {/* Display title only for the current image */}
                            {index === currentImageIndex && (
                                <div className="current-image-title">
                                    {image.title.split(' - ').map((part, index) => (
                                        <p key={index}>{part}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="loading">loading</p>
                )}

                <ul className='menu'>
                    <li><Link to="/digital">digital</Link></li>
                    <li><Link to="/">info</Link></li>
                </ul>

            </div>
        </>
    );

}