
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './App.scss';
import Slider from './components/Slider';
import SliderTest from './components/SliderTest';
import FlashingBackground from './components/FlashingBackground';
import SliderFlash from './components/SliderFlash';
import PicturesFlashing from './components/PicturesFlashing';
import PicturesFlashingMouse from './components/PicturesFlashingMouse';
import PicturesFlashingMouseAccumulation from './components/PicturesFlashingMouseAccumulation';
import Gradient from './components/Gradient';
import PicturesFlashingMouseSlow from './components/PicturesFlashingMouseSlow';
import Analog from './components/Analog';
import Digital from './components/Digital';
import Home from './components/Home';

import Digital02 from './components/Digital02';

function App() {
  window.addEventListener(
    'contextmenu',
    function (e) {
      e.preventDefault();
    },
    false
  );

  return (

    <Router>
      <Routes>

        {/*    <FlashingBackground /> */}
        {/*    <Slider /> */}


        {/*      <PicturesFlashing /> */}
        {/*  <Gradient /> */}



        {/*  <SliderFlash /> */}
        {/*     <SliderTest /> */}


        {/*   <PicturesFlashingMouse /> */}
        {/*       <PicturesFlashingMouseSlow /> */}

        {/*           <PicturesFlashingMouseAccumulation />
 */}

        <Route path="/" element={<Home />} />
        <Route path="/analog" element={<Analog />} />
        <Route path="/digital" element={<Digital />} />

      </Routes>
    </Router>


  );
}

export default App;
